document.addEventListener("DOMContentLoaded", () => {
    const navBar = document.createElement("nav");
    navBar.innerHTML = `
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/reports">Reports</a></li>
            <li><a href="/levels">Levels</a></li>
        </ul>
    `;
    document.body.insertBefore(navBar, document.body.firstChild);
});